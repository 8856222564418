import PortableText from "components/PortableText"
import PortableTitle from "components/shared/PortableTitle"
import SmallForm from "components/shared/SmallForm"
import { useSizing } from "lib/helpers"
import { urlForImage } from "lib/sanity.image"
import { HomeHeader } from "schemas"
import { Container, Grid, styled, VStack } from "styled-system/jsx"

type Props = HomeHeader

function Columns({
  title,
  body,
  inputLabel,
  ctaLabel,
  image,
  showForm,
  formPage,

  sizingTop,
  sizingBottom,
}: Props) {
  return (
    <styled.div
      className="homeHeader"
      style={
        {
          "--pt": useSizing(sizingTop) || "80px",
          "--pb": useSizing(sizingBottom) || "80px",
          "--ptm": "40px",
          "--pbm": "40px",
        } as React.CSSProperties
      }
      css={{
        pt: "var(--ptm, var(--pt))",
        pb: "var(--pbm, var(--pb))",
        bg: "#000A3A",
        color: "white",

        lg: {
          pt: "var(--pt)",
          pb: "var(--pb)",
        },
      }}>
      <Container>
        <Grid
          columns={{ lg: 2 }}
          gap={{ base: 8, lg: 20 }}
          gridTemplateColumns={{ lg: "1.5fr 1fr", xl: "1fr 1.35fr" }}
          alignItems="start">
          <VStack gap={{ base: "32px", md: "64px" }} alignItems="start">
            <PortableTitle
              as="h1"
              css={{
                color: "#F2F2F5",
                fontFamily: "heading",
                fontSize: { base: "46px", md: "71px" },
                fontWeight: 600,
                lineHeight: "136%",

                "& em": {
                  position: "relative",
                  color: "transparent",

                  "&:after": {
                    content: "attr(data-text)",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "110%",
                    height: "80%",
                    bg: "red",
                    // rounded: "1em",
                    rounded: "110px",
                    background:
                      "linear-gradient(270deg, #FFB48A 0%, rgba(218, 166, 251, 0.00) 94.48%)",
                    zIndex: 0,
                  },
                },
              }}>
              {title}
            </PortableTitle>
            <styled.div
              css={{
                color: "#F2F2F5",
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "133%",

                md: {
                  mb: 8,
                  fontSize: "23px",
                },
              }}>
              <PortableText value={body} />
            </styled.div>
          </VStack>
          <styled.div
            hideFrom="lg"
            css={{
              maxW: "740px",
            }}>
            <SmallForm
              inputProps={{
                placeholder: inputLabel,
              }}
              submitLabel={ctaLabel}
              buttonProps={{
                variant: "filled",
                fillColor: "primary",
                color: "white",
              }}
              formPage={formPage?.slug}
            />
          </styled.div>
          <styled.img
            alt={image.alt || ""}
            src={urlForImage(image).url()}
            css={{
              // Positions the header image correctly because Figma adds empty spaces around it
              transform: "scale(1.1)",
              transformOrigin: "left center",
            }}
          />
        </Grid>
        {showForm && (
          <styled.div
            hideBelow="lg"
            css={{
              color: "white",
              maxW: "725px",
            }}>
            <SmallForm
              inputProps={{
                placeholder: inputLabel,
              }}
              submitLabel={ctaLabel}
              buttonProps={{
                variant: "filled",
                fillColor: "primary",
              }}
              formPage={formPage?.slug}
            />
          </styled.div>
        )}
      </Container>
    </styled.div>
  )
}

export default Columns
export type { Props as ColumnsProps }
